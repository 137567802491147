import { Link } from '@remix-run/react';

import { type DtoProduct } from '@lp-lib/api-service-client/public';
import { getStaticAssetPath } from '@lp-lib/email-templates/src/utils';

import { ProductUtils } from '../../../src/components/Product/utils';
import config from '../../../src/config';

export function FeaturedPlanAdCard(props: { product: DtoProduct }) {
  const { product } = props;

  return (
    <div
      className={`relative w-200 h-80 bg-white rounded-xl px-7.5 py-6.5 flex flex-col justify-between gap-3`}
    >
      <img
        src={getStaticAssetPath('images/otp/choose-purchase-option-games.png')}
        alt=''
        className='absolute top-0 right-0 h-full'
      />

      <div className='w-120 text-3.5xl font-black font-Montserrat leading-tight'>
        <span className='text-red-006'>Unlimited Access</span>{' '}
        <span className='text-black'>for the Price of a Single Event!</span>
      </div>

      <ul className='flex-1 ml-3 text-base text-black flex flex-col gap-2'>
        {ProductUtils.HasUnlimitedLiveBooking(product) && (
          <li className='flex gap-2.5'>
            <p className='font-bold text-green-001'>✓</p>
            <p className='w-102'>Unlimited Live Hosted Events</p>
          </li>
        )}
        <li className='flex gap-2.5'>
          <p className='font-bold text-green-001'>✓</p>
          <p className='w-102'>
            Unlimited On Demand Icebreakers, Escape Rooms, and more
          </p>
        </li>
        <li className='flex gap-2.5'>
          <p className='font-bold text-green-001'>✓</p>
          <p className='w-102'>Starts at 2+ people and 10+ minutes</p>
        </li>
      </ul>

      <Link
        to={config.hubspot.bookDemoUrl}
        className='ml-5 btn rounded w-45 h-10 flex justify-center items-center bg-green-001 hover:bg-[#33c35b] transition-colors text-white'
        target='_blank'
      >
        Book a Demo
      </Link>
    </div>
  );
}

export function FeaturedPlanAdSlice() {
  return (
    <div
      className={`relative w-full h-full bg-white rounded-xl flex flex-col justify-center p-5`}
    >
      <img
        src={getStaticAssetPath('images/otp/choose-purchase-option-games.png')}
        alt=''
        className='absolute top-0 right-0 h-full'
      />

      <div className='w-50 text-xl font-black font-Montserrat leading-normal'>
        <span className='text-red-006'>Unlimited Access</span>{' '}
        <span className='text-black'>for the Price of a Single Event!</span>
      </div>
    </div>
  );
}
