import { type DtoPageSection } from '@lp-lib/api-service-client/public';

import {
  PageSectionRow,
  type PageSectionRowRenderProps,
} from './PageSectionRow';
import { pageRowAnchor } from './utils';

type PageSectionBodySharedProps = {
  section: DtoPageSection;
  sectionIndex: number;
};

export type PageSectionBodyGeneralProps = PageSectionBodySharedProps &
  PageSectionRowRenderProps;

export type PageSectionBodyProgramsProps = PageSectionBodySharedProps;

export type PageSectionBodyRenderProps = {
  renderGeneral: (props: PageSectionBodyGeneralProps) => JSX.Element;
  renderPrograms: (props: PageSectionBodyProgramsProps) => JSX.Element;
};

export type PageSectionBodyProps = PageSectionBodySharedProps &
  PageSectionBodyRenderProps;

export function PageSectionBodyGeneral(props: PageSectionBodyGeneralProps) {
  const { section } = props;

  if (!section.rows || section.rows.length === 0) return null;
  return (
    <div className='w-full max-w-[3200px] m-auto px-20 flex flex-col gap-7.5'>
      {section.rows.map((row, rowIndex) => (
        <div
          className='w-full'
          key={row.id}
          id={pageRowAnchor(row)}
          style={{
            scrollMarginTop: '20px',
          }}
        >
          <PageSectionRow
            row={row}
            rowIndex={rowIndex}
            sectionIndex={props.sectionIndex}
            section={props.section}
            renderFeaturedCarousel={props.renderFeaturedCarousel}
            renderFeaturedTag={props.renderFeaturedTag}
            renderTagCollection={props.renderTagCollection}
          />
        </div>
      ))}
    </div>
  );
}
