import { format } from 'date-fns';
import { type ReactNode, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { EnumsMediaType } from '@lp-lib/api-service-client/public';
import { type Media } from '@lp-lib/media';

import { GamePackCoverImage } from '../../../src/components/Game/GamePackCoverPres';
import { PlayIcon } from '../../../src/components/icons/PlayIcon';
import { type GamePack } from '../../../src/types/game';
import { fromMediaDTO } from '../../../src/utils/api-dto';
import { MediaUtils } from '../../../src/utils/media';
import {
  ComingSoonGamePackBadge,
  GamePackAudienceDisclaimer,
  GamePackDuration,
  GamePackPlayerRange,
  NewGamePackBadge,
  ThumbsUpBadge,
} from '../../components/GamePack/GamePackShared';
import {
  GamePackHoverBrandsPreviewMulti,
  GamePackHoverBrandsPreviewSingle,
} from '../GamePack/GamePackHover';
import { useGamePackShowcaseCards } from './hooks/useGamePackShowcaseCards';
import { isGamePackLaunched } from './utils';

function Trailer(props: {
  pack: GamePack;
  media: Media;
  hovered: boolean;
  onToggleAutoplay: (val: boolean) => void;
}) {
  const { pack, hovered, media, onToggleAutoplay } = props;

  const [playing, setPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const url = MediaUtils.PickMediaUrl(media);
  const poster =
    MediaUtils.PickMediaUrl(pack?.cover) || media?.firstThumbnailUrl;
  if (!url) return null;

  return (
    <div
      className='relative w-full h-full'
      onClick={(e) => e.stopPropagation()}
    >
      <video
        className={`w-full h-full rounded-l-xl object-cover ${
          playing ? 'opacity-100' : 'opacity-0 pointer-events-none'
        } transition-opacity duration-250`}
        src={url}
        poster={poster}
        controls={playing}
        ref={videoRef}
        onPlay={() => {
          setPlaying(true);
          onToggleAutoplay(false);
        }}
        onPause={() => {
          if (videoRef.current?.readyState !== 4) return;
          setPlaying(false);
          onToggleAutoplay(true);
        }}
      />

      <div
        className={`absolute inset-0 bg-lp-black-001 flex flex-col justify-center items-center gap-5
            ${
              hovered && !playing
                ? 'opacity-100'
                : 'opacity-0 pointer-events-none'
            } transition-opacity duration-250`}
        onClick={() => videoRef.current?.play()}
      >
        <button
          type='button'
          className='w-14 h-14  rounded-full flex justify-center items-center'
          style={{
            background: 'rgba(111, 111, 111, 1)',
          }}
        >
          <PlayIcon className='w-7 h-7 fill-current text-white' />
        </button>
      </div>
    </div>
  );
}

function Brands(props: { pack: GamePack; hovered: boolean }) {
  const { pack, hovered } = props;

  const { data: showcaseCards } = useGamePackShowcaseCards(pack);

  if (!hovered) return null;
  if (!showcaseCards || showcaseCards.length === 0) return null;

  return (
    <div className='relative w-full h-full'>
      <div
        className='absolute w-full h-full animate-fade-in-v2'
        style={
          {
            '--tw-fade-in-v2-duration': '0.3s',
          } as React.CSSProperties
        }
      >
        {showcaseCards.length === 1 ? (
          <GamePackHoverBrandsPreviewSingle
            play
            card={showcaseCards[0]}
            mediaClassName='rounded-r-none'
          />
        ) : (
          <GamePackHoverBrandsPreviewMulti
            cards={showcaseCards}
            play
            indicator='inner'
            mediaClassName='rounded-r-none'
          />
        )}
      </div>
    </div>
  );
}

export function GamePackFeaturedCard(props: {
  pack: GamePack;
  action?: ReactNode;
  onToggleAutoplay: (val: boolean) => void;
  onClick?: () => void;
  onViewed?: () => void;
}) {
  const { pack, action, onToggleAutoplay, onClick, onViewed } = props;

  const [hovered, setHovered] = useState(false);

  useEffectOnce(() => {
    if (!onViewed) return;
    onViewed();
  });

  return (
    <div
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
      className={`relative w-240 h-80 bg-layer-001 rounded-xl ${
        onClick ? 'cursor-pointer' : ''
      }`}
      onClick={onClick}
    >
      <div className='absolute top-0 right-2 transform -translate-y-3/4 flex items-center justify-end gap-1'>
        <NewGamePackBadge gamePack={pack} />
        <ComingSoonGamePackBadge gamePack={pack} />
      </div>

      <div className='h-full flex'>
        <div
          className='flex-none h-full rounded-l-xl relative overflow-hidden'
          style={{
            aspectRatio: '16/9',
          }}
        >
          <GamePackCoverImage
            pack={pack}
            className='absolute w-full h-full object-cover'
            alt={''}
          />

          <div className='absolute w-full h-full'>
            {pack.marketingSettings?.gameTrailer?.media?.type ===
            EnumsMediaType.MediaTypeVideo ? (
              <Trailer
                pack={pack}
                hovered={hovered}
                media={fromMediaDTO(pack.marketingSettings.gameTrailer.media)}
                onToggleAutoplay={onToggleAutoplay}
              />
            ) : (
              <Brands pack={pack} hovered={hovered} />
            )}
          </div>
        </div>
        <section className='px-5 py-4 flex-grow flex flex-col gap-2 min-w-0'>
          <header className='flex flex-col gap-1'>
            <div className='flex-1 flex gap-1 items-center'>
              {pack.detailSettings?.gameType && (
                <div className='font-Montserrat font-bold uppercase leading-5'>
                  {pack.detailSettings.gameType}
                </div>
              )}
              <div className='ml-auto'>
                <ThumbsUpBadge gamePack={pack} />
              </div>
            </div>
            <div className='text-2xl font-medium'>{pack.name}</div>
          </header>
          <p className='flex-shrink min-h-0 text-sm line-clamp-4'>
            {pack.description}
          </p>
          <GamePackAudienceDisclaimer
            audience={pack.detailSettings?.audience}
          />
          <div className='flex items-center gap-4 text-icon-gray'>
            <GamePackPlayerRange pack={pack} />
            <GamePackDuration pack={pack} />
          </div>

          <div className='flex-1 flex flex-col justify-end items-end gap-4'>
            {!isGamePackLaunched(pack) && (
              <div className='text-xl text-tertiary font-bold'>
                Launching{' '}
                {format(
                  new Date(pack.detailSettings?.availability?.launchDate || ''),
                  'M/d/yy'
                )}
                !
              </div>
            )}
            {action}
          </div>
        </section>
      </div>
    </div>
  );
}
