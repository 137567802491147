import { useEffectOnce } from 'react-use';

import { type Tag } from '../../../src/types';
import { TagSupportingMedia } from './TagSupportingMedia';
import { getTagStyles } from './utils';

export function TagFeaturedCard(props: {
  tag: Tag;
  onClick?: () => void;
  onViewed?: () => void;
}) {
  const { tag, onClick, onViewed } = props;

  const styles = getTagStyles(tag);

  useEffectOnce(() => {
    if (!onViewed) return;
    onViewed();
  });

  return (
    <div
      className={`relative w-200 h-80 flex rounded-xl overflow-hidden group ${
        onClick ? 'cursor-pointer' : ''
      }`}
      style={{
        background: styles.background,
      }}
      onClick={onClick}
    >
      <div className='flex-1 pl-12.5 pr-3 py-10 flex flex-col justify-between'>
        <div className='flex flex-col gap-3'>
          <div
            className={`font-Montserrat text-4xl font-black`}
            style={{
              color: styles.titleTextColor,
            }}
          >
            {tag.name}
          </div>
          <div className='text-base font-normal text-white tracking-wide'>
            {tag.description}
          </div>
        </div>
        <button
          type='button'
          className='btn rounded w-48 h-10 flex justify-center items-center bg-white bg-opacity-25 hover:bg-opacity-40'
        >
          See Experiences
        </button>
      </div>
      <TagSupportingMedia
        tag={tag}
        className='
          flex-none h-full object-cover rounded-r-xl 
          transform group-hover:scale-110 transition-transform duration-300 ease-in-out
        '
      />
    </div>
  );
}

export function TagFeaturedSlice(props: { tag: Tag }) {
  const { tag } = props;

  const styles = getTagStyles(tag);

  return (
    <div
      className={`w-full h-full flex rounded-xl`}
      style={{
        background: styles.background,
      }}
    >
      <div className='flex-1 h-full px-5 pr-0 flex items-center'>
        <div
          className='font-Montserrat text-2xl font-black'
          style={{ color: styles.titleTextColor }}
        >
          {tag.name}
        </div>
      </div>

      <TagSupportingMedia
        tag={tag}
        className='flex-none h-full object-cover rounded-r-xl'
      />
    </div>
  );
}
