import {
  type DtoPageRow,
  type DtoPageSection,
  type DtoTag,
  type DtoTagCollection,
  EnumsPageRowType,
} from '@lp-lib/api-service-client/public';

import { Ticker } from '../../../src/components/common/Ticker';
import { StarIcon } from '../../../src/components/icons/StarIcon';
import { getStaticAssetPath } from '../../../src/utils/assets';
import { assertExhaustive } from '../../../src/utils/common';

interface PageSectionRowSharedProps {
  row: DtoPageRow;
  rowIndex: number;
  sectionIndex: number;
  section: DtoPageSection;
}

export type PageSectionRowFeaturedCarouselContainerProps =
  PageSectionRowSharedProps;

export type PageSectionRowFeaturedTagContainerProps =
  PageSectionRowSharedProps & {
    tag: DtoTag;
  };

export type PageSectionRowTagCollectionContainerProps =
  PageSectionRowSharedProps & {
    collection: DtoTagCollection;
  };

export type PageSectionRowRenderProps = {
  renderFeaturedCarousel: (
    props: PageSectionRowFeaturedCarouselContainerProps
  ) => React.ReactNode;
  renderFeaturedTag: (
    props: PageSectionRowFeaturedTagContainerProps
  ) => React.ReactNode;
  renderTagCollection: (
    props: PageSectionRowTagCollectionContainerProps
  ) => React.ReactNode;
};

function PageSectionRowSocialProof() {
  return (
    <div className='w-full flex flex-col items-center gap-6'>
      <div className='flex items-center gap-1'>
        <StarIcon className='w-3.5 h-3.5 text-tertiary' />
        <div className='text-sms font-bold'>
          <span className='text-tertiary'>9.2</span>
          <span> rated by </span>
          <span className='text-tertiary'>90K+</span>
          <span> customers in 100+ countries</span>
        </div>
      </div>
      <Ticker className='max-w-360'>
        <img
          src={getStaticAssetPath('images/pages/row-social-proof.png')}
          alt=''
          className='h-10'
        />
      </Ticker>
    </div>
  );
}

export function PageSectionRow(
  props: PageSectionRowSharedProps & PageSectionRowRenderProps
) {
  const { row, rowIndex, section, sectionIndex } = props;

  switch (row.type) {
    case EnumsPageRowType.PageRowTypeFeaturedCarousel:
      return props.renderFeaturedCarousel({
        row,
        rowIndex,
        sectionIndex,
        section,
      });
    case EnumsPageRowType.PageRowTypeTag:
      if (!row.tag) return null;
      return props.renderFeaturedTag({
        tag: row.tag,
        row,
        rowIndex,
        sectionIndex,
        section,
      });
    case EnumsPageRowType.PageRowTypeTagCollections:
      if (!row.tagCollection) return null;
      return props.renderTagCollection({
        collection: row.tagCollection,
        row,
        rowIndex,
        sectionIndex,
        section,
      });
    case EnumsPageRowType.PageRowTypeSocialProof:
      return <PageSectionRowSocialProof />;
    default:
      assertExhaustive(row.type);
      return null;
  }
}
