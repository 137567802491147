import { type ReactNode, useEffect, useRef, useState } from 'react';

import { type DtoFeaturedItem } from '@lp-lib/api-service-client/public';

import { type AppAnalytics } from '../../../src/analytics/app/shared';
import {
  Glide,
  GlideArrows,
  GlideBullets,
  GlideLeftArrow,
  GlideRightArrow,
  GlideSlides,
  GlideTrack,
} from '../../../src/components/Game/GameCenter/Glider';
import { useSet } from '../../../src/hooks/useSet';
import { useSSRLayoutEffect } from '../../components/hooks/useSSRLayoutEffect';

// These effectively control the timing of the featured card animation.
const fadeInClass = 'animatejit-[fade-in_1s_ease_0.2s_1_both]';
const fadeOutClass = 'animatejit-[fade-out_0.2s_ease_1]';

export function FeaturedList(props: {
  items: DtoFeaturedItem[];
  renderSlide: (props: { item: DtoFeaturedItem; index: number }) => ReactNode;
  renderCard: (props: {
    item: DtoFeaturedItem;
    index: number;
    onToggleAutoplay: (val: boolean) => void;
  }) => ReactNode;
  onRun?: (index: number) => void;
  analytics?: AppAnalytics;
  hideBullets?: boolean;
}) {
  const { items, renderSlide, renderCard, onRun, analytics, hideBullets } =
    props;

  const glideRef = useRef<Glide | null>(null);
  const [glideInstanceNum, setGlideInstanceNum] = useState(0);
  const containerEl = useRef<HTMLDivElement | null>(null);
  const cardEl = useRef<HTMLDivElement | null>(null);
  const [currentIdx, setCurrentIdx] = useState<null | number>(0);
  const mutateExitingSlides = useSet<HTMLElement>();
  const [aborter] = useState(new AbortController());
  const currentItem = currentIdx === null ? null : items[currentIdx];
  const [autoplay, setAutoplay] = useState(true);

  useEffect(() => {
    // If items change in any way, reset everything. Should only happen during
    // admin clients when editing featured. We need to
    // manually manage resetting the glide instance since the only lifecycle
    // trigger is has is "unmount'.
    setGlideInstanceNum((i) => i + 1);
    setCurrentIdx(0);
  }, [items]);

  useSSRLayoutEffect(() => {
    const exitingSlides = mutateExitingSlides();
    for (const exiting of exitingSlides) {
      // Already transitioning/exiting.
      if (exiting.parentElement !== null) continue;

      // We know that react has removed it from the DOM (no parent), immediately
      // put it back so we can transition it!

      exiting.classList.remove(fadeInClass);
      exiting.classList.add(fadeOutClass);
      containerEl.current?.appendChild(exiting);
      exiting.addEventListener(
        'animationend',
        () => {
          exitingSlides.delete(exiting);
          exiting.remove();
        },
        {
          signal: aborter.signal,
        }
      );
    }
  }, [aborter.signal, mutateExitingSlides]);

  useSSRLayoutEffect(() => {
    return () => aborter.abort();
  }, [aborter]);

  return (
    <div
      ref={containerEl}
      className={`${hideBullets ? '' : 'px-10 mt-3.5 mb-14'}
         w-full h-80 relative flex-none
      `}
    >
      <Glide
        className='h-full'
        key={glideInstanceNum}
        options={{
          type: 'carousel',
          perView: 3,
          focusAt: 'center',
          autoplay: autoplay ? 7000 : false,
          hoverpause: true,
          animationDuration: 200,
          // There is a visual glitch when using gap size > 0 when going
          // "backwards" in the carousel, and only during the first step.
          // https://github.com/glidejs/glide/issues/657
          gap: 0,
        }}
        onInit={(glide) => {
          glideRef.current = glide;
          glide
            .on('run.before', () => {
              // The order is important here: we only want the effect to run
              // once we can guarantee the element has been removed from the
              // DOM.

              // Grab a reference to the element...
              const current = cardEl.current;

              // Force the current card to be forgotten by React so that only we
              // have a reference...
              setCurrentIdx(null);

              // Assuming we had a valid reference, grab it to be animated out!
              if (current) {
                mutateExitingSlides().add(current);
              }
            })
            .on('run', () => {
              // Once the next action is triggering, update what is considered
              // the current slide.
              setCurrentIdx(glide.index);
              if (onRun) {
                onRun(glide.index);
              }
            });
        }}
      >
        {hideBullets ? (
          <></>
        ) : (
          <div className='absolute w-full -bottom-8 flex justify-center items-center'>
            <GlideBullets
              slidesCount={items.length}
              className='w-240 bg-lp-gray-009 flex justify-center items-center'
              bulletClassName='btn '
              activeBulletClassName='bg-lp-gray-010'
              bullet={
                <button
                  type='button'
                  className='btn h-1'
                  style={{ width: `${100 / items.length}%` }}
                />
              }
            />
          </div>
        )}

        <GlideTrack className='h-full'>
          <GlideSlides
            className='h-full'
            slideClassName='h-full flex justify-center items-center'
            onSlidePointerUp={(idx) => {
              if (!glideRef.current) return;

              // Need to have special handling for "looping around" the carousel
              // to avoid resetting the carousel back to the beginning or end
              // visually. We want it to always appear infinite, which requires
              // using "next" or "prev" commands instead of direct indices.
              const current = glideRef.current.index;
              const op =
                current === 0 && idx === items.length - 1
                  ? '<'
                  : current === items.length - 1 && idx === 0
                  ? '>'
                  : `=${idx}`;

              glideRef.current?.go(op);
              analytics?.trackFeaturedGamePackCarouselClick('bgCard');
            }}
            slides={items.map((item, index) => (
              <div
                key={item.id}
                className={`
                  h-56 w-100 relative rounded-xl overflow-hidden shadow
                  cursor-pointer
                `}
              >
                {renderSlide({ item, index })}
                {/* Shadow Overlay Layer to darken the card */}
                <div className='absolute left-0 top-0 right-0 bottom-0 bg-lp-black-001' />
              </div>
            ))}
          />
        </GlideTrack>

        <GlideArrows>
          <GlideLeftArrow
            className='absolute -left-6 top-1/2 transform -translate-y-1/2'
            onPointerUp={() => {
              analytics?.trackFeaturedGamePackCarouselClick('prevButton');
            }}
          />
          <GlideRightArrow
            className='absolute -right-6 top-1/2 transform -translate-y-1/2'
            onPointerUp={() => {
              analytics?.trackFeaturedGamePackCarouselClick('nextButton');
            }}
          />
        </GlideArrows>

        {currentItem ? (
          <div
            ref={(el) => (cardEl.current = el)}
            key={`featured-item-${currentItem.id}`}
            className={`
            absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2
            text-white
            rounded-xl ring-1 ring-secondary
            shadow-featured-game-card
            opacity-0 ${fadeInClass}
          `}
          >
            {renderCard({
              item: currentItem,
              index: currentIdx || 0,
              onToggleAutoplay: setAutoplay,
            })}
          </div>
        ) : (
          <></>
        )}
      </Glide>
    </div>
  );
}
