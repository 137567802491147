import { Waypoint } from 'react-waypoint';

import {
  type DtoPageSection,
  type EnumsPageName,
} from '@lp-lib/api-service-client/public';

import { type AppAnalytics } from '../../../src/analytics/app/shared';
import {
  PageSectionBodyGeneral,
  type PageSectionBodyRenderProps,
} from './PageSectionBody';
import { PageSectionContainer } from './PageSectionContainer';
import { type PageSectionRowRenderProps } from './PageSectionRow';
import { pageSectionAnchor } from './utils';

export type PageSectionListProps = {
  pageName: EnumsPageName;
  sections: DtoPageSection[];

  onFocusedSectionChange?: (section: DtoPageSection, index: number) => void;

  renderPrograms: PageSectionBodyRenderProps['renderPrograms'];
  analytics?: AppAnalytics;
} & PageSectionRowRenderProps;

export function PageSectionList(props: PageSectionListProps) {
  const { sections, onFocusedSectionChange } = props;

  return (
    <div className='w-full flex flex-col gap-7.5 pb-10'>
      {sections.map((section, sectionIndex) => (
        <Waypoint
          key={section.id}
          fireOnRapidScroll
          topOffset={200}
          onEnter={(args) => {
            if (args.previousPosition === Waypoint.above) {
              if (onFocusedSectionChange) {
                onFocusedSectionChange(section, sectionIndex);
              }
            }
          }}
          onLeave={(args) => {
            if (
              args.currentPosition === Waypoint.above &&
              sectionIndex + 1 < sections.length
            ) {
              const next = sections[sectionIndex + 1];
              if (next && onFocusedSectionChange) {
                onFocusedSectionChange(next, sectionIndex + 1);
              }
            }
          }}
        >
          <div
            className='w-full'
            key={section.id}
            id={pageSectionAnchor(section)}
          >
            <PageSectionContainer
              pageName={props.pageName}
              section={section}
              sectionIndex={sectionIndex}
              analytics={props.analytics}
              renderBodyGeneral={() => (
                <PageSectionBodyGeneral
                  section={section}
                  sectionIndex={sectionIndex}
                  renderFeaturedCarousel={props.renderFeaturedCarousel}
                  renderFeaturedTag={props.renderFeaturedTag}
                  renderTagCollection={props.renderTagCollection}
                />
              )}
              renderBodyPrograms={() =>
                props.renderPrograms({ section, sectionIndex })
              }
            />
          </div>
        </Waypoint>
      ))}
    </div>
  );
}
