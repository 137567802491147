import { useRef, useState } from 'react';

export function useSet<T>(init = () => new Set<T>()) {
  const [s] = useState<Set<T>>(init());
  const inited = useRef(false);
  const nextMutate = () => s;
  const [getter, updateGetter] = useState(() => nextMutate);

  if (!inited.current) {
    const add_ = s.add;
    const delete_ = s.delete;
    const clear_ = s.clear;

    s.add = (v) => {
      if (s.has(v)) return s;
      add_.call(s, v);
      updateGetter(() => () => s);
      return s;
    };
    s.delete = (v) => {
      const ret = delete_.call(s, v);
      if (ret) updateGetter(() => () => s);
      return ret;
    };
    s.clear = () => {
      clear_.call(s);
      updateGetter(() => () => s);
    };
    inited.current = true;
  }

  return getter;
}
