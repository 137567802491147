import { useEffect, useState } from 'react';
import { useLatest } from 'react-use';
import { Waypoint } from 'react-waypoint';

import { type Tag } from '../../../src/types';
import { TagSupportingMedia } from './TagSupportingMedia';
import { getTagStyles } from './utils';

function VisibleWatcher(props: { onViewed: () => void }) {
  const [horizontalVisible, setHorizontalVisible] = useState(false);
  const [verticalVisible, setVerticalVisible] = useState(false);
  const onVisibleRef = useLatest(props.onViewed);

  useEffect(() => {
    if (horizontalVisible && verticalVisible) {
      onVisibleRef.current();
    }
  }, [horizontalVisible, onVisibleRef, verticalVisible]);

  return (
    <>
      {/* 
        we only care about the first time it's visible.
        remove the waypoint once it is visible.
      */}
      {!verticalVisible && (
        <div className='absolute bottom-0'>
          <Waypoint
            fireOnRapidScroll
            onEnter={() => {
              setVerticalVisible(true);
            }}
          />
        </div>
      )}
      {!horizontalVisible && (
        <div className='absolute right-0'>
          <Waypoint
            fireOnRapidScroll
            horizontal
            onEnter={() => {
              setHorizontalVisible(true);
            }}
          />
        </div>
      )}
    </>
  );
}

export function TagCard(props: {
  tag: Tag;
  onClick?: () => void;
  onViewed?: () => void;
}) {
  const { tag, onClick, onViewed } = props;

  const styles = getTagStyles(tag);

  return (
    <div
      className={`relative w-full h-65 flex rounded-2.5xl overflow-hidden group ${
        onClick ? 'cursor-pointer' : ''
      }`}
      style={{
        background: styles.background,
      }}
      onClick={onClick}
    >
      <div className='flex-1 p-7.5 flex flex-col gap-2.5'>
        <div
          className={`font-Montserrat text-2xl 2xl:text-3.5xl font-black`}
          style={{
            color: styles.titleTextColor,
          }}
        >
          {tag.name}
        </div>
        <div className='text-sm 2xl:text-base font-normal tracking-wide'>
          {tag.description}
        </div>
      </div>

      <TagSupportingMedia
        tag={tag}
        className='flex-none h-full object-cover rounded-r-2.5xl transform group-hover:scale-110 transition-transform duration-300 ease-in-out'
      />

      {onViewed && <VisibleWatcher onViewed={onViewed} />}
    </div>
  );
}
